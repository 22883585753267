import { graphql } from "gatsby"
import React from "react"
import BlogPage from "../components/pages/Blog/template"
import Layout from "../layouts/Main"

interface IProps {
  pageContext: {
    slug: string
  }
  data: {
    allStrapiBlogCategories: {
      edges: {
        node: {
          slug: string
          title: string
        }
      }[]
    }
    allStrapiBlogPosts: {
      edges: {
        node: {
          title: string
          slug: string
          excerpt: string
          originalPublishDate: number
          thumbnail: Strapi.UploadFile
          author: {
            slug: string
            fullName: string
            avatar: Strapi.UploadFile
          }
        }
      }[]
    }
  }
}

const BlogPostCategoryTemplate = (props: IProps) => {
  const { data } = props
  return (
    <Layout>
      <BlogPage
        categorySlug={props.pageContext.slug}
        posts={data.allStrapiBlogPosts.edges.map(e => e.node)}
        categories={data.allStrapiBlogCategories.edges.map(e => e.node)}
      />
    </Layout>
  )
}

export const query = graphql`
  query ($slug: String!) {
    allStrapiBlogCategories {
      edges {
        node {
          slug
          title
        }
      }
    }
    allStrapiBlogPosts(
      filter: { blog_categories: { elemMatch: { slug: { eq: $slug } } } }
    ) {
      edges {
        node {
          title
          slug
          excerpt
          originalPublishDate
          thumbnail {
            url
            localFile {
              childImageSharp {
                gatsbyImageData(placeholder: BLURRED, width: 200)
              }
            }
          }
          author {
            slug
            fullName
            avatar {
              localFile {
                childImageSharp {
                  gatsbyImageData(placeholder: BLURRED, width: 100)
                }
              }
            }
          }
        }
      }
    }
  }
`

export default BlogPostCategoryTemplate
